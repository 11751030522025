(function ($) {

  var Locator = {

    init: function () {

      if ($('#store-locator-map').length) {
        if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
          var cluster = document.createElement('script');
          cluster.src = '/themes/gaia/assets/js/gmap.clustering.min.js';
          document.head.appendChild(cluster);
          var script = document.createElement('script');
          script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCmp5v6eYefrVSmhcnngoYRRjRVpRCmcsk&libraries=geometry,places&callback=Locator.initMap';
          document.head.appendChild(script);
        } else {
          //console.log('Google Maps is already loaded');
          Locator.initMap();
        }
      }
    },

    initMap: function () {
      var $locator = $('#store-locator-map');
      Locator.datagps = $locator.data('gps') || null;
      // Init map
      Locator.map = new google.maps.Map($locator.get(0), {
        center: { lat: 47.5, lng: 0},
        zoom: 6,
        minZoom: 2,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        styles: Locator.config.mapStyles
      });

      $.getJSON("/wordpress/wp-admin/admin-ajax.php?action=load_locations", function (data) {
        Locator.initStoreLocator(data);
      });
    },

    initStoreLocator: function (data) {
      // Init markers
      var map         = Locator.map;
      var cards       = null;
      var markers     = [];
      var locations   = data.locations;
      var markerCluster         = null;
      var search_result_center  = null;
      var resultmin       = 10; // Min results to free max zoom
      var lowresultzoom   = 16; // In case of low search results
      var RADIUS          = 10000; // Search around 10 kms
      var marker_selected = null;
      var filters         = $('.map-filter');
      var filters_array   = [];

      if(null !== Locator.datagps) {
        var $datagps = Locator.datagps.split(',');
        var $latlng = new google.maps.LatLng($datagps[0], $datagps[1]);
        var $marker = new google.maps.Marker({
          map: map,
          position: $latlng,
          icon: setIcon(0)
        });
        map.setZoom(17);
        map.panTo($marker.position);
      }

      else {

        var defaultBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(40, -5),
          new google.maps.LatLng(50, 10)
        );

        // Prepare events on filters
        initFilters();
        
        // Prepare events on search
        var input = document.getElementById('map-search-input');
        var select_country = document.getElementById('map-search-countries');
        positionAutocomplete();
        
        var countries = ['fr', 'ch', 'be','lu'];
        
        var options = {
            types: ['(cities)']
          };
        
        var searchBox = new google.maps.places.Autocomplete(input);
            searchBox.setComponentRestrictions({'country': countries}); // up to five countries
            searchBox.setFields(['geometry','name']);
            
        $(select_country).on('change', function() {
          if(this.value == 2)
            countries = ['de'];
          else if(this.value == 3)
            countries = ['ca','pm'];
          else if(this.value == 4)
            countries = ['es'];
          else if(this.value == 5)
            countries = ['gp', 'gf', 'mq'];
          else if(this.value == 6)
            countries = ['re'];
          else if(this.value == 7)
            countries = ['nc'];
          else if(this.value == 8)
            countries = ['tw'];
          else 
            countries = ['fr', 'ch', 'be','lu'];
            
          searchBox.setComponentRestrictions({'country': countries});
        });
        
        map.addListener('bounds_changed', function() {
          searchBox.setBounds(map.getBounds());
          $('.map-popin').removeClass('active');
        });

        // Ready, so Set All markers
        if (locations.length) {
          $.each(locations, function (key, val) {
            createMarker(val);
          });
        }

        // ========== Geolocation ==========
        var infoposition = document.getElementById("infoposition") || null;
        
        if(infoposition && navigator.geolocation) {
          survId = navigator.geolocation.getCurrentPosition(myPos,errorPos,{maximumAge:600000,enableHighAccuracy:true});
        } else {
          console.log("Ce navigateur ne supporte pas la géolocalisation");
        }
          
        function myPos(position) {
          var infopos = "Position déterminée :\n";
          infopos += "Latitude : "+position.coords.latitude +" ";
          infopos += "Longitude : "+position.coords.longitude+" ";
          //infoposition.innerHTML = infopos;
          console.log(infopos);
          
          latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          
          map.panTo(latlng);
          if (map.getZoom() < 16) map.setZoom(16);
        }
        
        function errorPos(error) {
          var info = "Erreur lors de la géolocalisation : ";
            switch(error.code) {
              case error.TIMEOUT:
                info += "Timeout !";
              break;
              case error.PERMISSION_DENIED:
                info += "Vous n'avez pas donné la permission";
              break;
              case error.POSITION_UNAVAILABLE:
                info += "La position n'a pu être déterminée";
              break;
              case error.UNKNOWN_ERROR:
                info += "Erreur inconnue";
              break;
            }
          //infoposition.innerHTML = info;
        }
        // ========== End Geolocation ==========

        var message = $('#map-message');
				message.on( "click", function() {
					$(this).css('display','none');
				});

        var message = $('#map-message');

        var placelatlng;
        searchBox.addListener('place_changed', function() {
          
          message.css('display','none');
          
          var places = searchBox.getPlace();
          
          if (places.length == 0) {
            return;
          }

          var results = 0;
          map.setOptions({maxZoom:21});
          
          var bounds = new google.maps.LatLngBounds();
          
          placelatlng = new google.maps.LatLng(places.geometry.location.lat(), places.geometry.location.lng());
          
          cards = [];

          // This searches markers around selected adress item, and/or a salon name matches with selected item
          markers.forEach(function(marker) {
            var markerlatlng = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
            var distance_from_location = google.maps.geometry.spherical.computeDistanceBetween(placelatlng, markerlatlng);
            if (distance_from_location <= RADIUS) {
              var $match = search_match(places.name, marker.itemdata.nom);
              if($match !== -1) {
                search_result_center = markerlatlng;
                setMarkerActive(marker);
              }
              bounds.extend(marker.getPosition());
              cards.push(marker.itemdata);
              results++;
            }
          });
          
          // If no results, relaunch with greater radius (30 Kms)
          if(results < 1) {
            cards = [];
            markers.forEach(function(marker) {
              var markerlatlng = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
              var distance_from_location = google.maps.geometry.spherical.computeDistanceBetween(placelatlng, markerlatlng);
              if (distance_from_location <= (RADIUS*6)) {
                var $match = search_match(places.name, marker.itemdata.nom);
                if($match !== -1) {
                  search_result_center = markerlatlng;
                  setMarkerActive(marker);
                }
                bounds.extend(marker.getPosition());
                cards.push(marker.itemdata);
                results++;
              }
            });
          }

          if(search_result_center !== null ) {
            map.panTo(search_result_center);
            map.setZoom(15);
            search_result_center = null;
          }

          else {
          
            if(results > 0 ) {
              map.fitBounds(bounds);
              if(results < 4) {
                map.setZoom(13);
              }
            }
            else {
              map.panTo(placelatlng);
              map.setZoom(11);
              message.css('display','block');
            }
          }
        });
      }

      // ========== Clean search ==========
      function search_match (place_selected = null, salon_name = null) {
        var place_selected = slugify(place_selected.toLowerCase().substring(0, 8));
        var salon_name     = slugify(salon_name.toLowerCase().substring(0, 8));
        return salon_name.indexOf(place_selected);
      }

      // ========== Hack positionning autosuggest ==========
      function positionAutocomplete() {
        setTimeout(function(){ 
          $(".pac-container").prependTo("#map-search-wrapper");
        }, 300);
      }

      // ========== Set Markers / Cluster ==========
      function createMarker(item) {
        var lat = parseFloat(item.lat);
        var lng = parseFloat(item.lng);
        var latlng = new google.maps.LatLng(lat, lng);

        var marker = new google.maps.Marker({
          map: map,
          position: latlng,
          icon: setIcon(item.cat),
          title: item.nom,
          itemdata: item
        });

        google.maps.event.addListener(marker, 'click', function () {
          popMarker(marker);
        });

        markers.push(marker);
      }

      // ========== Create Cluster ==========
      var clusterStyles = [
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m1b.png', height: 32, width: 32 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m2b.png', height: 32, width: 32 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m3b.png', height: 48, width: 48 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m4b.png', height: 48, width: 48 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m5b.png', height: 48, width: 48 }
      ];

      markerCluster = new MarkerClusterer(map, markers, {styles: clusterStyles, maxZoom: 11, gridSize: 44});

      // ========== Inputs filter init ==========
      function initFilters() {
        filters.on('click touch', function (e) {
          filters_array = [];
          $('.map-filter').not(this).prop('checked', false);
          if($(this).is(':checked')) {
            filters_array.push($(this).val());
          }
          updateMap();
        });
      }

      // ========== Markers / Cluster update ==========
      function updateMap() {
        markerCluster.clearMarkers();
        markers = [];

        if(filters_array.length < 1) {
          $.each(locations, function (key, val) {
            createMarker(val);
          });
        }

        else {

          $.each(locations, function (key, val) {
            if(((filters_array.includes("colorist") && val.colorist == 1) 
              || (filters_array.includes("vente_soins") && val.vente_soins == 1) 
              || (filters_array.includes("vrac_soin") && val.vrac_soin == 1)) 
              || (filters_array.includes("is_adom") && val.is_adom == 1) 
            ) {
              createMarker(val);
            }
          });
        }

        markerCluster.addMarkers(markers);
      }

      function setMarkerActive(marker) {
        if(null !== marker_selected) {
          marker_selected.setIcon(setIcon());
        }
        marker.setIcon(setIconSelected());
        marker_selected = marker;
      }

      function popMarker(marker) {
        setMarkerActive(marker);
        var $popin = $('.map-popin');
        var $page_url = $($popin).data('url');
        $('.js-popin-close').on('click touch', function (e) {
          e.preventDefault();
          $popin.removeClass('active');
        });

        $popin.addClass('active');
        setContentPopin($popin, marker.itemdata, $page_url);
      }

      function setIcon() {
        return {
          url: Locator.config.mapIconData,
          anchor: ({ x: 11, y: 32 }),
          scaledSize: new google.maps.Size(22, 32),
        };
      }

      function setIconSelected() {
        return {
          url: Locator.config.mapIconDataScaled,
          anchor: ({ x: 15, y: 41 }),
          scaledSize: new google.maps.Size(30, 41),
        };
      }

      // Open popin salon over map
      function setContentPopin($popin, item, $page_url) {
        var $this = this;
        $('.map-popin__name').html(item.nom);
        $('.map-popin__partner span').html(item.date_colorist);
        $('.map-popin__adress address').html(item.address);
        $('.map-popin__adress span').html(item.zip + ' ' + item.ville);
        var $tags = '';
        if (item.colorist == 1) $tags += '<span>Coloration végétale</span>';
        if (item.vente_soins == 1) $tags += '<span>Produit de soin</span>';
        if (item.vrac_soin == 1 || item.vrac_couleur == 1) $tags += '<span>Vente en vrac</span>';
        $('.map-popin__tags').html($tags);

        // Open card detail of a salon
        var $btn_card = $popin.find('.js-locator-page-card');
        $btn_card.off('click').one('click', function (e) {
          $.ajax({
            dataType: "json",
            type: "GET",
            cache: false,
            url: "/wordpress/wp-admin/admin-ajax.php?action=get_salon&salonkey=" + item.key,
            success: function(data) {
              setContentCard(data, item);
              if ($(window).width() < 768) {
                App.scroll($('.card-salon-wrapper'));
              }
              App.sendEvent('clic-trouver-salon-partenaire','clic-CTA-voir-la-fiche',item.nom);
            }
          });
        });

        // Select salon from map 
        var $btn_salon = $popin.find('.js-selected-salon');
          $btn_salon.off('click').one('click touch', function (e) {
          $.ajax({
            dataType: "json",
            type: "GET",
            cache: false,
            url: "/wordpress/wp-admin/admin-ajax.php?action=get_salon_selected&salonkey=" + item.key,
            success: function(data) {
              setChoiceSalon(data);
            }
          });
        });
      }

      // Set salon content detail and map zoom to marker
      function setContentCard(data, item) {
        var $current_center = Locator.map.getCenter();
        var $current_zoom = Locator.map.getZoom();
        var $container = $('.card-salon-wrapper');
        var $backbtn = $container.find('.js-back-to-map');
        var $cardsalon = $container.find('.card-salon');
        $backbtn.removeAttr('onclick');
        $cardsalon.empty();
        $cardsalon.html(data);
        
        // Zoom map to selected salon
        Locator.map.panTo(new google.maps.LatLng(item.lat, item.lng));
        Locator.map.setZoom(17);

        $($backbtn).one('click touch', function (e) {
          $('body').removeClass('card-salon-opened');
          // Reverse to previous position
          Locator.map.panTo($current_center);
          Locator.map.setZoom($current_zoom);
        });

        $('.map-popin').removeClass('active');
        $('body').addClass('card-salon-opened');
      }

      // Display selected salon detail in parent page and close map.
      function setChoiceSalon(data) {
        var $usr_referral_salon = $('#usr_referral_salon') || null;
        var $target_data = $('#usr_referral_salon_data') || null;

       if(undefined !== $target_data) {
          $usr_referral_salon.val(data.codesalon);
          $target_data.empty();
          $target_data.html(data.datasalon);
          $target_data.parent().find('.salon-selection').addClass('selected');
          var $btn = $target_data.parent().find('.btn-salon-select').addClass('selected');
              $btn.removeClass('btn--solid').addClass('btn--url');
              $btn.text("Modifier");
          // register-link-salon
          document.dispatchEvent(new CustomEvent("onChoiceSelected"));
        }
      }

      function slugify (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes
        return str;
      }
    },

    config: {
      mapStyles: [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }],
      mapIcon: "M10.932 1.276c-2.869 0-5.62 1.14-7.648 3.168s-3.168 4.78-3.168 7.648c0 9.977 9.472 18.251 9.937 18.603 0.245 0.211 0.558 0.326 0.881 0.326s0.636-0.116 0.881-0.326c0.474-0.352 9.937-8.627 9.937-18.603 0-1.421-0.28-2.828-0.824-4.14s-1.341-2.505-2.346-3.51c-1.005-1.004-2.198-1.801-3.511-2.344s-2.72-0.823-4.141-0.822zM10.932 16.148c-0.802 0-1.586-0.238-2.253-0.684s-1.187-1.079-1.494-1.82c-0.307-0.741-0.387-1.556-0.231-2.343s0.543-1.509 1.11-2.076c0.567-0.567 1.29-0.953 2.076-1.11s1.602-0.076 2.343 0.231c0.741 0.307 1.374 0.827 1.82 1.494s0.684 1.451 0.684 2.253c0 1.076-0.427 2.107-1.188 2.868s-1.792 1.188-2.868 1.188z",
      mapIconData: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAgCAYAAAAWl4iLAAADO0lEQVRIS7WVbUhTYRTHn3M3U9A0CsoMhaiMsPBlMykkMCiCXqDojUgI3e51FvhJiqKQogI/BWLuXp1B1IfSD2WQn3oRKrJyrqyQEgojjSjEkoR5t6f/vebY3L1uc/TAdu92zvnd8/zPOc8l9p8WmXE5Z+Rtc2xgQWELI1oHvzzOuYrrsCDQG05qj83hGTKLNwT3Kc69jNFFfDSg6cKDemGss0uKdo1YEWDe0CB4c0ZuAng4IYU4P2OTlEvhMSGwvnVF6mTE9iUEDTnzRpuonJz5GQK/ksWzRHR+ftDpKCJ2oMQpd+r32hegZYA+TwaqxULzP9agkF/kcn+dBitiPzEqShasx3N+A3pXUr/i3BxkwtM5aj8kENUVO+X7enGzR7czgUMyKjWLIat/KfXJYiPEqTd04uxjZvoC+5qjTb/C7YOeqoUTqrUf8q0yiiMWrCbo+xAOFUYOyLSi2Ol+bGTztkr70UkdRjZo3UJ9ivjeaBA447/topJptt2BVscyP7d8MwHfQcbSANpkfZQDZ2M2SV5sCm6vzfWrgWFjO++CxlI3mm6HoRQBWlnscn82lEJxVnImXDeuDW/WNG6Fxg6TzG7bRPnQbNuna8fSfvpTP2CnucbFY6c08JwTxxnrtASofiZzr6cqJ6habyGZctN2I3aEvG5pExfYs1jDgQ54C58URnw1hskyl7/ex/rkydIotpUdCx6PHa32CMfoVh3cp0hXcKmLJzCWD4CuElF262Cf4swPMBrUz6dkFmcTaaplecHxqxMhEAblLsB7kuECdhnZntYYITCKaEcRX84XjOJO4mjLg74/IsC61rLUhUftng88PNsosK+lZkVA4IOAZyQG50Npi8YKCg52+GfiooqFY/QEatiUCNgiUFmRw/0iPCYKrL1UUch7mKyd8cDRtxeg67nZvobt9a65NmPSqvrMDvIQhLMH6IJtaFJMfuQy7dvXHtfaKTXQC3iWUebIdCArPbV89tvFVONwiK+tZqMaDPbgbEgL/x9yfUkJkr3Q5f5uJlfMScPLtjTAhW5sd4kGAbRfsE7tKqluH5mrBjHB/0a+ACP/BPfjABeiWOOxChsXOBbEyP4XvJArS5IT42YAAAAASUVORK5CYII=",
      mapIconDataScaled: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAA3CAYAAAB+fggjAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARPSURBVHgBzZo9cxtVFIbfsyt5hFEGURBEJ3cUJNHEFHTYHV2kLiSeQR6woYvT0SX+BZG7jJQZlJnYZIYCU0EX0aXAYUlS0LFUUUiBMiOPFTu7J/esLFmWV9LeXa2Vp5H2467fvfecez5kQkRKhUwmkUrlyUCOgFz3vEvchEPWa8Cu3W/YCAkhBCtXPsgTzEsgKgCcDzDEVn+o7ji4e+d+ow4NtAR+czm7QCZuqEELCI/NjPXqVqMW5OZAAkuXs7mkiR8iChvE5n2nWP3phTXqJhNjWP3ybMFI4FcCfYzJkiHT+G7+XBo7T1q/D7tp5AyuLmVvgHETccO8vT/zarlWazYHLw0VeGrijqRY+8m9xUGRht+tK0sfXjtdcQLnkwepW4NnT9igOESC6DdMAbWc+flPzrzcedp62D13YgZnTDzANDH4luyzvcP+a57d9UWDqUFmb6l7AmVpld2V8BYg+60EBfneE5g0vE04h2h4IU0ZvIWISMTyPrsnVq9m/0EIgaySArWJb8y22uXy9tEWsaaSiN3ZVIEIoc0myZTzBHrBn8w/oQ1ZhsPF22OylZWlj8rEfA26uHTdW2ImYwH62EHECdV7z9Zc4C40YYMveQJNxufQxHWwfFsjz3udbK8xuAkNlOnkD2dQz0ZYOYJuXichjIg2oAVnOgI1jZhc+gUhYNfZhiaeQDWVGZ1BLofbRqpbL7THGQgBk6v1QlEIJdCEmUMI+mNsULoCbehArrbXdzDDCtRzf6h904vdmhxGFS06Al36C1pwRooonRHhMiVqHu6DpDeD6GQcq1eygWZExfmvwmToamO3vFj89dLZgsnGzwiBPOTAoaJf90C6DjPvvqNegtcQAiWu5gksldSDDlL/IxKk3tatM9NLIn7PVWEqah2tCvzlyOlWnBgO5nr7oHrzUOErLsR0JBk5EmiwdpyMFYbXbegJvHOvUddNh+LEdKksn8dCnX46FA+SznVzzWMC9xPtMt4G+Cj7PtZZsKx2e/58ek591Y6ZE8RWvcPl7sGJbMYl1DBFpLnZf+zb3fr2avYBT7ZZGRS7stmY6z/hmw+SKogwBQZnT/DtsP7xtNW8eOHM+2p6P8Ppccz2ugzNqA8SezdPc1/0mz1haI9aPPrT8+lXasG/QMxI1lLZavgKHFmTVDaflzvNoFixlc2vD7s4tmgSh4lzqWVpR3Uoxgr0BjNdRwzI0o77QWfs7yTCoyctKwavtmd328WHf7fbo24KXBeLV0+iMSlIT1Elo4v9/cRhBBYozR9D1R6TsEdiYz1oZ0yrsyAPVRVgEVFQTlHZfBY4awpkg/08etyyL55L/6uK8AI0YeaN6tbz73XGaAsUxGnmL6Ql0VgIPoos5bHasx9KoLDzuFUPLpKs2d29xXEe60dogUIwkR1xQTzWj0gChdEio4kTIgsUfEUSbStxxSjivMdgghz+s0VBNaOtyo//TaTOfgMV37A5x4ipYAAAAABJRU5ErkJggg=="
    }
  };
  window.Locator = Locator;
  $(document).ready(Locator.init);
})(jQuery);

// https://www.option40.com/blog/supercharge-gmaps